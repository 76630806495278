<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <ValidationObserver tag="form" ref="form">
                <CForm>
                  <h1>Reset Password</h1>
                  <validation-provider
                    rules="required|email"
                    v-slot="{ errors }"
                    name="Email"
                  >
                    <CInput
                      placeholder="Email"
                      autocomplete="email"
                      prepend="@"
                      v-model="email"
                    />
                    <div class="errMsg">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </validation-provider>
                  <ValidationProvider
                    rules="required|min:12"
                    v-slot="{ errors }"
                    :bails="false"
                    tag="div"
                    name="password"
                    mode="lazy"
                  >
                    <CInput
                      placeholder="Password"
                      type="password"
                      autocomplete="new-password"
                      v-model="password"
                    >
                      <template #prepend-content>
                        <CIcon name="cil-lock-locked" />
                      </template>
                    </CInput>
                    <div
                      class="errMsg"
                      v-for="(error, index) in errors"
                      :key="index"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                  <ValidationProvider
                    rules="required|confirmPassword:@password"
                    v-slot="{ errors }"
                    :bails="false"
                    tag="div"
                    mode="lazy"
                    name="repeat password"
                  >
                    <CInput
                      placeholder="Repeat password"
                      type="password"
                      autocomplete="new-password"
                      class="mb-4"
                      v-model="confirmPassword"
                    >
                      <template #prepend-content
                        ><CIcon name="cil-lock-locked"
                      /></template>
                    </CInput>
                    <div
                      class="errMsg"
                      v-for="(error, index) in errors"
                      :key="index"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                  <CButton color="success" block @click="sendBtn()"
                    >送出</CButton
                  >
                </CForm>
              </ValidationObserver>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
    <!-- 共用錯誤彈窗 -->
    <Modal></Modal>
  </div>
</template>

<script>
import Modal from '../Modal.vue'
export default {
  name: 'ResetPassword',
  data() {
    return {
      token: this.$route.params.token,
      email: '',
      password: '',
      confirmPassword: '',
    }
  },
  components: {
    Modal,
  },
  methods: {
    sendBtn() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          let data = {
            token: this.token,
            email: this.email,
            password: this.password,
            password_confirmation: this.confirmPassword,
          }

          this.$store.dispatch('actionResetPW', data)
        } else {
          console.log('NO')
        }
      })
    },
  },
}
</script>
